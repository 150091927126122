
import { defineComponent } from 'vue';
import {Button, Table, Searchbar} from '@/ui/';
import { Work } from '@/model';
import { WorkController } from '@/controller/';
import store from "@/store";

export default defineComponent({
    name: "WorkList",
    components: { Button, Searchbar, Table },
    created(){
        this.handleGetWorks();
    },
    data(){
        return{
            store,
            worksTableData:{
                thFields: [{title:this.$t('label.title'), property: 'title', type: 'string', clickAction: 'editWork'}, {title: this.$t('label.description'), property: 'description', type: 'string'}, {title: this.$t('label.defaultHourlyRate'), property: 'defaultHourlyRate', type: 'string'} ],
                tbActions: [{ name: "editWork", icon: store.getters.svgIcons.pencil }, { name: "confirmDelete", icon: store.getters.svgIcons.remove }]
            },
            works: [] as Array<Work>,
            searchInput: ""
        }
    },
    computed: {
        filteredWorks() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.works.forEach((work : any) => {
                    JSON.stringify(work.title).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(work);
                    !filtered.includes(work) && JSON.stringify(work.description).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(work);
                })
            } else {
                filtered = this.works;
            }
            return filtered;
        }
    },
    methods:{
        async handleGetWorks() {
            const res = await WorkController.fetchWorks();
            if(!res.error){
                this.works = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleTableActionClicked(action : string, item:any) {
            switch(action) {
                /*case "openDetail":
                    this.handleOpenDetailView(item.uid);
                break*/
                case "confirmDelete":
                    this.handleConfirmDelete(item);
                break
                case "editWork":
                    this.handleEdit(item.uid);
                break
            }
        },
        handleShowCreateForm() {
            this.$router.push('/administration/work/form/0');
        },
        handleEdit(uid:number) {
            this.$router.push("/administration/work/form/" + uid);
        },
        handleConfirmDelete(item : any) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteWorkText', { name: item.title }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete(item.uid) },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async handleDelete(uid : number) {
            const res = await WorkController.deleteWork(uid)
            if (!res.error) {
                this.works = this.works.filter(function(item : any){
                   return item.uid != res.work.uid;
                });
                this.$notify(this.$t("success.workDeleted"), { position: "top" });
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
